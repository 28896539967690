import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Ensure js-cookie is installed
import axios from 'axios';
import './Profile.css';

const Profile = () => {
    const [states, setStates] = useState([]); // To store state options
    const [cities, setCities] = useState([]); // To store city options
    const [selectedCountry, setSelectedCountry] = useState(''); // Selected country
    const [selectedState, setSelectedState] = useState(''); // Selected state
    const [position, setPosition] = useState({ x: 0, y: 0 }); // For drag-and-drop functionality
    const [loading, setLoading] = useState(true); // For loading state
    const [error, setError] = useState(null); // To store errors
    const [profileData, setProfileData] = useState({
        name: '',
        companyName: '',
        email: '',
        country: '',
        state: '',
        city: '',
        address: '',
        pincode: '',
        mobile: '',
        additionalNumber: '',
        businessList: '',
    });

    const sessionID = Cookies.get('sessionID'); // Get sessionID from cookies
    const user = Cookies.get('user'); // Check for user data in cookies

    useEffect(() => {
        if (!user) {
            setError("You are not logged in. Please log in to access your profile.");
            setLoading(false);
            return; // Stop further execution if no user is found
        }

        const fetchProfileData = async () => {
            if (!sessionID) {
                setError("Session not found. Please log in again.");
                setLoading(false);
                return;
            }
            try {
                const response = await axios.get('https://tionet.in/node/api/auth/getUserProfile', {
                    headers: {
                        Authorization: `Bearer ${sessionID}`,
                    },
                    withCredentials: true, // Ensure cookies are sent along with the request
                });
                setProfileData(response.data); // Populate profile data
                setSelectedCountry(response.data.country);
                setSelectedState(response.data.state);
            } catch (error) {
                console.error("Error fetching profile data:", error);
                setError("Failed to fetch profile data. Please try again.");
            } finally {
                setLoading(false);
            }
        };
        fetchProfileData();
    }, [sessionID, user]); // Depend on sessionID and user to re-run the effect when these values change

    // Load states based on the selected country
    const loadStates = () => {
        if (selectedCountry) {
            setStates(['State 1', 'State 2', 'State 3']); // Replace with actual logic to fetch states
        } else {
            setStates([]);
        }
    };

    // Load cities based on the selected state
    const loadCities = () => {
        if (selectedState) {
            setCities(['City A', 'City B', 'City C']); // Replace with actual logic to fetch cities
        } else {
            setCities([]);
        }
    };

    // Handle drag-and-drop positioning
    const handleMouseDown = (e) => {
        e.preventDefault();
        const startX = e.clientX - position.x;
        const startY = e.clientY - position.y;
        const handleMouseMove = (e) => {
            const newX = e.clientX - startX;
            const newY = e.clientY - startY;
            if (newX >= 0 && newY >= 0 && newX + 300 <= window.innerWidth && newY + 400 <= window.innerHeight) {
                setPosition({ x: newX, y: newY });
            }
        };
        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://tionet.in/node/api/auth/updateUserProfile', profileData, {
                headers: {
                    Authorization: `Bearer ${sessionID}`,
                },
                withCredentials: true, // Ensure cookies are sent along with the request
            });
            console.log("Profile updated successfully");
        } catch (error) {
            console.error("Error updating profile data:", error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div 
            className="profile-container"
            style={{ 
                display: 'block', 
                position: 'absolute',
                transform: `translate(${position.x}px, ${position.y}px)`,
                zIndex: 1000
            }}
            onMouseDown={handleMouseDown}
        >
            <div className="main-container">
                <h2>Profile</h2>
                <input type="file" id="imageUpload" accept="image/*" style={{ display: 'none' }} />
                <button id="uploadButton" className="imageupload">Update Image</button>
                <button id="updateInfoButton" className="editprofile">Edit Profile</button>
            </div>
            <div className="sub-container">
                <div className="profile-section-one">
                    <form className="userprofile" onSubmit={handleSubmit}>
                        <div className="input-box">
                            <span className="details">Name:</span>
                            <input 
                                type="text" 
                                placeholder="Enter Your Name" 
                                name="name"
                                value={profileData.name} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Company Name:</span>
                            <input 
                                type="text" 
                                placeholder="Company Name" 
                                name="companyName"
                                value={profileData.companyName} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Email:</span>
                            <input 
                                type="email" 
                                placeholder="Email" 
                                name="email"
                                value={profileData.email} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Country:</span>
                            <select 
                                className="from-select country" 
                                name="country"
                                value={selectedCountry}
                                onChange={(e) => {
                                    setSelectedCountry(e.target.value); 
                                    handleInputChange(e);
                                    loadStates();
                                }}
                            >
                                <option value="">Select Country</option>
                                {/* Add country options here */}
                                <option value="Country 1">Country 1</option>
                                <option value="Country 2">Country 2</option>
                            </select>
                        </div>
                        <div className="input-box">
                            <span className="details">State:</span>
                            <select 
                                className="from-select state" 
                                name="state"
                                value={selectedState}
                                onChange={(e) => {
                                    setSelectedState(e.target.value); 
                                    handleInputChange(e);
                                    loadCities();
                                }}
                            >
                                <option value="">Select State</option>
                                {states.map((state, index) => (
                                    <option key={index} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-box">
                            <span className="details">City:</span>
                            <select 
                                className="from-select city" 
                                name="city"
                                value={profileData.city}
                                onChange={handleInputChange}
                            >
                                <option value="">Select City</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-box">
                            <span className="details">Address:</span>
                            <input 
                                type="text" 
                                placeholder="Enter Address" 
                                name="address"
                                value={profileData.address} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Pincode:</span>
                            <input 
                                type="number" 
                                placeholder="Enter Pincode" 
                                name="pincode"
                                value={profileData.pincode} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Mobile Number:</span>
                            <input 
                                type="tel" 
                                placeholder="Mobile Number" 
                                name="mobile"
                                value={profileData.mobile} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Additional Mobile Number:</span>
                            <input 
                                type="tel" 
                                placeholder="Additional Number" 
                                name="additionalNumber"
                                value={profileData.additionalNumber} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <span className="details">Business List:</span>
                            <input 
                                type="text" 
                                placeholder="Business List" 
                                name="businessList"
                                value={profileData.businessList} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className="input-box">
                            <input type="submit" value="Save" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Profile;
